import React from "react";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

export const Section1 = () => {
  const intl = useIntl();
  return (
    <div className="section1">
      <div className="text-content">
        <h3>
          <FormattedMessage id="Section1.Title1" />
        </h3>
        <p>
          <FormattedMessage id="Section1.Paragraph1" />
        </p>
        <p>
          <FormattedMessage id="Section1.Paragraph2" />
        </p>

        <h3>
          <FormattedMessage id="Section1.Title2" />
        </h3>
        <p>
          <FormattedMessage id="Section1.Paragraph3" />
        </p>
        <p>
          <FormattedMessage id="Section1.Paragraph4" />
        </p>
        <p>
          <FormattedMessage id="Section1.Paragraph5" />
        </p>
        <p>
          <FormattedMessage id="Section1.Paragraph6" />
        </p>
      </div>
    </div>
  );
};
