import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.scss";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";
import videoData from "./data.json";
import { FormattedMessage } from "gatsby-plugin-intl";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

export const SwiperSlider = ({ videoInput }) => {
  const [videoUrl, setVideoUrl] = useState(
    videoInput === "Jobs"
      ? "https://www.youtube.com/embed/OFKv-wGBuQE"
      : "https://www.youtube.com/embed/V-up-FNMwkQ"
  );
  const [videoAutoPlay, setVideoAutoPlay] = useState(false);
  const setStateVideo = (videourl) => {
    setVideoUrl(videourl);
  };
  return (
    <div className="video-container">
      <h3 className="title">
        <FormattedMessage
          id={videoInput === "Jobs" ? "VideoTitle1" : "VideoTitle2"}
        />
      </h3>
      <div className="videoContainer">
        <div className="videoIframe">
          <iframe
            className="responsiveIframe"
            src={videoUrl}
            frameborder="0"
            autoPlay={videoAutoPlay}
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <Swiper
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={
          typeof window !== `undefined` ? (window.innerWidth < 768 ? 2 : 4) : 4
        }
        loop={true}
        spaceBetween={25}
        navigation={true}
        clickable={false}
        slideToClickedSlide={false}
        coverflowEffect={{
          rotate: 25,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{
          clickable: false,
        }}
        className="mySwiper"
      >
        {videoInput === "Jobs" && (
          <>
            {videoData.jobs.videoList.map((video) => {
              return (
                <SwiperSlide key={video.imgPath}>
                  <img src={video.imgPath} />
                  <div
                    className="playIcon"
                    onClick={() => setStateVideo(video.videoUrl)}
                  ></div>
                </SwiperSlide>
              );
            })}
          </>
        )}
        {videoInput === "Region" && (
          <>
            {videoData.region.videoList.map((video) => {
              return (
                <SwiperSlide key={video.imgPath}>
                  <img src={video.imgPath} />
                  <div
                    className="playIcon"
                    onClick={() => setStateVideo(video.videoUrl)}
                  ></div>
                </SwiperSlide>
              );
            })}
          </>
        )}
      </Swiper>
    </div>
  );
};
