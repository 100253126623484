import styled from "styled-components";

export const $Ukraine = styled.div`
  font-family: "roboto" !important;
  font-size: 1rem;
  .header {
    height: 50vh;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
      height: 80vh;
    }

    h1 {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      padding: 0.5rem;
      color: #fff;
      background-color: #00893a;
      span {
        color: #ffcc00;
      }
    }
    .img {
      background: url("../../../../Ukraine-Hands-Up.png") no-repeat center
        center;
      height: 100%;
      background-size: cover;
      border-bottom: 5px solid #00893a;
    }
  }
  .section1 {
    position: relative;
    @media only screen and (min-width: 1024px) {
      margin: 2rem 0;
      &:before {
        content: "";
        border-radius: 50em;
        padding: 1em;
        top: 50%;
        display: inline-block;
        background: url("../../../../Resolu-team.png") no-repeat center center;
        background-size: cover;
        transform: translate(2.5rem, -50%);
        width: 27vw;
        height: 27vw;
        border: 10px solid green;
        z-index: -1;
        position: absolute;
      }
    }

    .text-content {
      h3 {
        color: #eb5536;
      }
      margin-left: auto;
      padding: 1rem 1rem;
      @media only screen and (min-width: 768px) {
        padding: 1rem 2rem;
      }
      @media only screen and (min-width: 1024px) {
        width: 68%;
        padding: 1rem 3rem 1rem 0;
      }
    }
  }
  .hr {
    height: 4px;
    background-color: #00893a;
    width: 80%;
    margin: auto;
  }
  .jobs-container {
    /* text-align: center; */
    padding: 1rem;
    @media only screen and (min-width: 768px) {
      padding: 2.5rem;
    }
    @media only screen and (min-width: 1024px) {
      padding: 5rem;
    }
    h3 {
      color: #eb5536;
      text-transform: uppercase;

      @media only screen and (min-width: 1024px) {
        text-align: center;
      }
    }
    span {
      color: #eb5536;
      font-weight: bold;
    }

    .job {
      text-align: center;
      color: #fff;
      cursor: pointer;
      padding: 2rem 1rem;
      width: 100%;
      height: 100%;
      background: url("../../../SVG/carte-vert.svg") no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .view-more {
        color: #fff;
        padding-top: 2rem;
        bottom: 0;
        display: block;
      }
    }
  }
`;
