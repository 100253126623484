import React from "react";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

export const HeaderUkraine = () => {
  const intl = useIntl();

  return (
    <div className="header">
      <h1>
        <FormattedMessage id="Header.Title.Part1" />
        <br />
        <span>
          <FormattedMessage id="Header.Title.Part2" />{" "}
        </span>
        <FormattedMessage id="Header.Title.Part3" />
      </h1>
      <div className="img" />
    </div>
  );
};
