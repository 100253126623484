import React, { useRef, useState } from "react";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { Col, Row } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Application from "../body/offerApplication";
import { ReactSVG } from "react-svg";

export const JobsOffer = () => {
  const childRef = useRef();
  const [modalState, setModalState] = useState(false);
  const [jobsNumber, setJobsNumber] = useState(0);
  const openAndSetJob = (jobsNumber) => {
    setModalState(!modalState);
    setJobsNumber(jobsNumber);
  };
  const OpenApplication = () => {
    setModalState(!modalState);
    childRef.current.handleOfferApplicationVisibility(true);
  };

  const intl = useIntl();
  return (
    <div className="jobs-container">
      <div>
        <h3 style={{ textTransform: "uppercase" }}>
          <FormattedMessage id="JobsOfferSection.Title" />
        </h3>
        <p>
          <FormattedMessage id="JobsOfferSection.Paragraph1" />
        </p>
        <p>
          <FormattedMessage id="JobsOfferSection.Paragraph2" />
        </p>
        <span>
          <FormattedMessage id="JobsOfferSection.Opportunity" />
        </span>
      </div>
      <div className="jobs-offer">
        <Row id="jobList" className="job-list">
          <Col
            xl="4"
            md="4"
            sm="6"
            xs="12"
            style={{ padding: 5, cursor: "auto" }}
            onClick={() => openAndSetJob(11328)}
          >
            <div className="job">
              <FormattedMessage id={"JobsOfferSection.Jobs1.Title"} />
              <span className="view-more">
                <FormattedMessage id={"JobsOfferSection.SeeMore"} />
              </span>
            </div>
          </Col>
          <Col
            xl="4"
            md="4"
            sm="6"
            xs="12"
            style={{ padding: 5, cursor: "auto" }}
            onClick={() => openAndSetJob(11330)}
          >
            <div className="job">
              <FormattedMessage id={"JobsOfferSection.Jobs2.Title"} />
              <span className="view-more">
                <FormattedMessage id={"JobsOfferSection.SeeMore"} />
              </span>
            </div>
          </Col>
          <Col
            xl="4"
            md="4"
            sm="6"
            xs="12"
            style={{ padding: 5, cursor: "auto" }}
            onClick={() => openAndSetJob(11332)}
          >
            <div className="job">
              <FormattedMessage id={"JobsOfferSection.Jobs3.Title"} />
              <span className="view-more">
                <FormattedMessage id={"JobsOfferSection.SeeMore"} />{" "}
              </span>
            </div>
          </Col>
        </Row>
        <Dialog
          onClose={() => setModalState(!modalState)}
          fullWidth={true}
          maxWidth={"sm"}
          open={modalState}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setModalState(!modalState)}
            style={{ textAlign: "center" }}
          >
            {jobsNumber === 11328 ? (
              <FormattedMessage id={"JobsOfferSection.Jobs1.Title"} />
            ) : jobsNumber === 11330 ? (
              <FormattedMessage id={"JobsOfferSection.Jobs2.Title"} />
            ) : (
              <FormattedMessage id={"JobsOfferSection.Jobs3.Title"} />
            )}
          </DialogTitle>
          <DialogContent dividers style={{ fontSize: "1rem" }}>
            <FormattedMessage id={"JobsOfferSection.YourRoles"} />
            <ul>
              {jobsNumber === 11328 ? (
                <>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs1.Task1"} />
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs1.Task2"} />
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs1.Task3"} />
                  </li>
                </>
              ) : jobsNumber === 11330 ? (
                <>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs2.Task1"} />
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs2.Task2"} />
                  </li>
                </>
              ) : (
                <>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs3.Task1"} />
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs3.Task2"} />
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    <FormattedMessage id={"JobsOfferSection.Jobs3.Task3"} />
                  </li>
                </>
              )}
            </ul>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", fontSize: "1rem" }}>
            <span
              className="postulationCTA"
              style={{
                display: "flex",
                flexFlow: "row-reverse",
                alignItems: "center",
                cursor: "pointer",
              }}
              size="lg"
              onClick={() => OpenApplication()}
              block
            >
              <FormattedMessage id={"JobsOfferSection.CTA"} />

              <ReactSVG
                className="icon-advantage"
                style={{ padding: "0 1rem" }}
                src={`../../../../SVG/arrow-list.svg`}
              ></ReactSVG>
            </span>
          </DialogActions>
        </Dialog>
      </div>
      <Application ref={childRef} jobId={jobsNumber} from="ukraine" />
    </div>
  );
};
