import React from "react";
import BaseLayout from "../components/BaseLayout";
import { HeaderUkraine } from "../components/ukraine/Header";
import { graphql } from "gatsby";
import { $Ukraine } from "../styles/SC_Ukraine";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { Section1 } from "../components/ukraine/Section1";
import { JobsOffer } from "../components/ukraine/JobsOffer";
import { SwiperSlider } from "../components/video/swiper";

const Ukraine = () => {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");
  return (
    <BaseLayout
      destailPage={true}
      page="ukraine"
      footerMap={false}
      footerAdress={false}
    >
      <$Ukraine>
        <HeaderUkraine />
        <Section1 />
        <div className="hr" />
        <JobsOffer />
        <SwiperSlider videoInput="Jobs" />
        <SwiperSlider videoInput="Region" />
      </$Ukraine>
    </BaseLayout>
  );
};

export default Ukraine;
